import { configureStore } from "@reduxjs/toolkit";
import storeTracking from "../../feature/StoreTracking/Slice";
import toggleSider from "../../shared/component/HeaderComponent/HeaderComponentSlice";
import tracking from "../../feature/Tracking/Slice";
import lineProfile from "../../feature/LineRegister/Slice";
import { useDispatch } from "react-redux";

const reducer = {
  tracking,
  toggleSider,
  storeTracking,
  lineProfile,
};

export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV === "development",
});

// export type of root state from reducers
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
