import * as ApiEndPoint from "../../shared/api/ApiEndPoint";

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ApiClient from "../../shared/api/ApiClient";
import { RootState } from "../../shared/store/store";

export type ShippingInfo = {
  dc_code: string;
  truck_plate_number: string;
  truck_type: string;
  driver_mobile_number: string;
  lat: string | null;
  long: string | null;
  last_location_timestamp: string | null;
  eta_start: string;
  eta_end: string;
  status: "waiting" | "in-transit" | "arrived" | "delivered" | "success";
  active: boolean;
};

export type StoreOwner = {
  store_number: string;
  store_name: string;
  amphoe: string;
  province: string;
  shipping_infos: ShippingInfo[] | null;
  uploaded_time: string;
};

export type TrackingState = {
  storeOwner: StoreOwner | null;
  activeDriver: ShippingInfo | null;
};

const initialValues: TrackingState = {
  storeOwner: null,
  activeDriver: null,
};

export const fetchStoreOwner = createAsyncThunk(
  "request/store_owner",
  async (storeNumber: string, { rejectWithValue }) => {
    try {
      const response = await ApiClient.post(ApiEndPoint.STORE_OWNER, { store_number: storeNumber });
      return response.data;
    } catch (err) {
      if (err instanceof Error) {
        console.log(err);
        return rejectWithValue(err.message);
      }
    }
  },
);

const tracking = createSlice({
  name: "tracking",
  initialState: initialValues,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStoreOwner.fulfilled, (state, action) => {
      state.storeOwner = action.payload;

      if (state.storeOwner?.shipping_infos) {
        for (const info of state.storeOwner.shipping_infos) {
          if (info.active) {
            state.activeDriver = info;
            break;
          }
        }
      }
    });

    builder.addCase(fetchStoreOwner.rejected, (state) => {
      state.storeOwner = null;
      state.activeDriver = null;
    });

    builder.addCase(fetchStoreOwner.pending, (state) => {
      state.storeOwner = null;
      state.activeDriver = null;
    });
  },
});

export const storeOwnerSelector = (store: RootState) => store.tracking.storeOwner;
export const activeDriverSelector = (store: RootState) => store.tracking.activeDriver;
export default tracking.reducer;
