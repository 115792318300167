import { Button, Form, Input, Modal } from "antd";
import "./style.scss";
import liff from "@line/liff";
import { useAppDispatch } from "../../shared/store/store";
import { useEffect, useState } from "react";
import {
  ConfirmOTP,
  LineProfile,
  confirmOTP,
  lineProfile,
  lineRegisterResponse,
  lineRegisterErrorResponse,
  lineRegisterLoading,
  lineRegisterOTPLoading,
  lineRegisterVerifyOTPErrorResponse,
  lineRegisterVerifyOTPFinished,
} from "./Slice";
import { useSelector } from "react-redux";
import OtpInput from "react18-input-otp";
import { phoneFormatMasked } from "../../utils/PhoneFormat";
import { CheckCircleOutlined } from "@ant-design/icons";

enum StateVisible {
  REGISTER,
  CONFIRM_OTP,
  SUCCESS,
}

export default function LineRegister() {
  const resendCountdown = 60;

  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [userProfile, setUserProfile] = useState("");
  const [countdown, setCountdown] = useState(resendCountdown);
  const [otp, setOtp] = useState("");
  const [storeNumber, setStoreNumber] = useState("");
  const [modal, contextHolder] = Modal.useModal();
  const [stateVisible, setStateVisible] = useState<StateVisible>(StateVisible.REGISTER);

  const loading = useSelector(lineRegisterLoading);
  const otpLoading = useSelector(lineRegisterOTPLoading);
  const registerResponse = useSelector(lineRegisterResponse);
  const registerErrorResponse = useSelector(lineRegisterErrorResponse);
  const verifyOTPErrorResponse = useSelector(lineRegisterVerifyOTPErrorResponse);
  const verifyOTPFinished = useSelector(lineRegisterVerifyOTPFinished);

  const [registerPayload, setRegisterPayload] = useState<LineRegisterPayload>({
    masked_mobile_phone: "",
    otp_ref: "",
  });

  type LineRegisterPayload = {
    masked_mobile_phone: string;
    otp_ref: string;
  };

  useEffect(() => {
    const lineLiffId = process.env.REACT_APP_LINE_LIFF_ID;
    const initLiff = async () => {
      try {
        await liff.init({ liffId: lineLiffId || "" });
        if (!liff.isLoggedIn()) {
          liff.login();
        } else {
          const profile = await liff.getProfile();
          setUserProfile(profile.userId);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    initLiff();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => (prevCountdown > 0 ? prevCountdown - 1 : prevCountdown));
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const modalError = (message: string) => {
    modal.error({
      centered: true,
      content: message,
    });
  };

  const onFinishForm = async (values: any) => {
    const lineRegisterData: LineProfile = {
      store_number: values.storeNumber,
    };

    setStoreNumber(values.storeNumber);

    dispatch(lineProfile(lineRegisterData));
  };

  const verifyOTPErrorMsg = (code: string): string => {
    switch (code) {
      case "otp_not_found": {
        return "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง";
      }
      case "otp_has_exceed_limit": {
        return "คุณกรอก OTP ผิดเกิน 5 ครั้ง กรุณาขอ OTP ใหม่";
      }
      case "otp_has_expired": {
        return "OTP ของคุณหมดอายุแล้ว กรุณาขอ OTP ใหม่";
      }
      case "otp_is_invalid": {
        return "รหัส OTP ไม่ถูกต้อง กรุณากรอกใหม่อีกครั้ง";
      }
      default: {
        return "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง";
      }
    }
  };

  useEffect(() => {
    if (registerResponse) {
      setRegisterPayload({
        masked_mobile_phone: registerResponse.masked_mobile_phone,
        otp_ref: registerResponse.otp_ref,
      });
      setStateVisible(StateVisible.CONFIRM_OTP);
    } else {
      setRegisterPayload({
        masked_mobile_phone: "",
        otp_ref: "",
      });
    }
  }, [registerResponse]);

  useEffect(() => {
    if (!registerErrorResponse) {
      return;
    }

    modalError("รหัสร้านค้าไม่ถูกต้อง \n กรุณากรอกใหม่อีกครั้ง");
  }, [registerErrorResponse]);

  useEffect(() => {
    if (!verifyOTPErrorResponse) {
      return;
    }

    const errMsg = verifyOTPErrorMsg(verifyOTPErrorResponse.code);
    modalError(errMsg);
    setOtp("");
  }, [verifyOTPErrorResponse]);

  useEffect(() => {
    if (!verifyOTPFinished) {
      return;
    }

    setStateVisible(StateVisible.SUCCESS);
  }, [verifyOTPFinished]);

  const onClickConfirmOtp = async () => {
    const confirmOtpData: ConfirmOTP = {
      otp: otp,
      otp_ref: registerPayload.otp_ref,
      line_uid: userProfile,
      store_number: storeNumber,
    };

    dispatch(confirmOTP(confirmOtpData));
  };

  const resentOtp = async () => {
    const lineRegisterData: LineProfile = {
      store_number: storeNumber,
    };

    dispatch(lineProfile(lineRegisterData));

    setCountdown(resendCountdown);
  };

  const onChangeOtp = (otp: string) => {
    setOtp(otp);
  };

  return (
    <div className="wrap-register-page">
      <div className="wrap-form wrap-form-register">
        {stateVisible === StateVisible.REGISTER && (
          <Form form={form} layout="vertical" name="line_login" autoComplete="off" onFinish={onFinishForm}>
            <Form.Item
              label="รหัสร้านค้า"
              name="storeNumber"
              rules={[
                {
                  required: true,
                  message: "กรุณาใส่รหัสร้านค้า",
                },
              ]}
            >
              <Input
                onChange={(e) => {
                  setStoreNumber(e.target.value.trim());
                }}
              />
            </Form.Item>
            <Form.Item>
              <Button style={{ width: "100%" }} className="login-btn" htmlType="submit" loading={loading}>
                ยืนยัน
              </Button>
            </Form.Item>
          </Form>
        )}
        {stateVisible === StateVisible.CONFIRM_OTP && (
          <div>
            <div className="wrap-otp-input">
              <p>กรุณากรอกรหัส OTP 6 หลัก</p>
              <p>
                ที่ส่งไปที่เบอร์ <span>{phoneFormatMasked(registerPayload.masked_mobile_phone)}</span>
              </p>
              <p>
                OTP Ref: <span>{registerPayload.otp_ref.slice(-6)}</span>
              </p>
              <OtpInput
                value={otp}
                onChange={onChangeOtp}
                numInputs={6}
                shouldAutoFocus={true}
                isInputNum={true}
                separator={<span> </span>}
                inputStyle="otp-input-style"
              />
              <p>คุณไม่ได้รับ OTP ใช่ไหม ?</p>

              {countdown == 0 ? (
                <Button onClick={resentOtp}>ส่งอีกครั้ง</Button>
              ) : (
                <p>กรุณาลองใหม่อีกครั้งใน {countdown}</p>
              )}
            </div>
            <div className="wrap-confirm-btn-otp">
              <Button disabled={otp.length < 6} onClick={onClickConfirmOtp} loading={otpLoading}>
                ยืนยัน OTP
              </Button>
            </div>
          </div>
        )}
        {stateVisible === StateVisible.SUCCESS && (
          <div className="wrap-confirm-otp-success">
            <CheckCircleOutlined rev={""} className="success-icon" />
            <p>ลงทะเบียนสำเร็จ</p>
          </div>
        )}
        {contextHolder}
      </div>
    </div>
  );
}
