import "./style.scss";

import { Layout } from "antd";
import { RootState } from "../../../shared/store/store";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

export default function HeaderComponent() {
  const { Header } = Layout;

  const { store_number } = useParams();

  const storeNumber = useSelector((state: RootState) => state.tracking.storeOwner?.store_number);

  const storeName = useSelector((state: RootState) => state.tracking.storeOwner?.store_name);

  return (
    <Header>
      <div className="top-left-items">
        <span>สวัสดี, {storeName} </span>
        <span>รหัสร้านค้า {store_number || storeNumber}</span>
      </div>
      <div className="top-right-items">{/* Profile icon in pharse 2 */}</div>
    </Header>
  );
}
