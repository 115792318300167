import notfoundImage from "../../assets/images/not-found.png";
import errImage from "../../assets/images/error.png";
import { useSearchParams } from "react-router-dom";

const ErrorPage = () => {
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        height: "100vh",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <img
          src={status === "404" ? notfoundImage : errImage}
          width={status === "404" ? 140 : 200}
          height={status === "404" ? 194 : 200}
          alt="error"
        />
        <div style={{ fontSize: "20px", fontWeight: "bold", color: "#4A4739" }}>
          {status === "404" ? "ไม่พบข้อมูลการจัดส่ง" : "เกิดข้อผิดพลาดการเชื่อมต่อ"}
        </div>
        <div style={{ fontSize: "16px", color: "#7B7768" }}>
          {status === "404" ? "เนื่องจากมีเปลี่ยนแปลงข้อมูล" : "กรุณาลองอีกครั้ง"}
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
