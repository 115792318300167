import { ENV, HTTP_STATUS_CODE } from "../../utils/Constants";

import axios from "axios";

const headersOptions = {
  "Content-Type": "application/json;charset=UTF-8",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Credentials": "true",
  "Access-Control-Allow-Methods": "DELETE, POST, GET, PUT, PATCH, OPTIONS",
  "Access-Control-Allow-Headers":
    "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
};

const ApiClient = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  timeout: 30000,
  headers: headersOptions,
});

ApiClient.interceptors.request.use(
  function (config) {
    // const token = localStorage.getItem('token');
    // if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

ApiClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error != null && error.response != null && error.response.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
      localStorage.removeItem("token");
      if (process.env.NODE_ENV !== ENV.DEVELOPMENT) {
        // window.location = process.env.REACT_APP_LOGIN_URL;
      }
    }
    return Promise.reject(error);
  },
);

export default ApiClient;

export const httpClientTD = axios.create({
  baseURL: process.env.REACT_APP_API_HOST_TD,
  timeout: 30000,
  headers: headersOptions,
});

httpClientTD.interceptors.request.use(
  function (config) {
    // const token = localStorage.getItem('token');
    // if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

httpClientTD.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error != null && error.response != null && error.response.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
      localStorage.removeItem("token");
      if (process.env.NODE_ENV !== ENV.DEVELOPMENT) {
        // window.location = process.env.REACT_APP_LOGIN_URL;
      }
    }
    return Promise.reject(error);
  },
);
