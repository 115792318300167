import ApiClient, { httpClientTD } from "../../shared/api/ApiClient";
import { LINE_REGISTER, LINE_VERIFY_OTP } from "../../shared/api/ApiEndPoint";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AxiosError } from "axios";
import { ErrorResponse } from "../../shared/type/ErrorResponseType";
import { RootState } from "../../shared/store/store";

export type LineProfile = {
  store_number: string;
};

export type ConfirmOTP = {
  otp_ref: string;
  otp: string;
  line_uid: string;
  store_number: string;
};

export type LineRegisterResponse = {
  otp_ref: string;
  masked_mobile_phone: string;
};

type CreateLineState = {
  loading: boolean;
  registerResponse: LineRegisterResponse | null;
  registerErrorResponse: ErrorResponse | null;

  otpLoading: boolean;
  verifyOTPErrorResponse: ErrorResponse | null;
  verifyOTPFinished: boolean;
};

const initialValues: CreateLineState = {
  loading: false,
  registerResponse: null,
  registerErrorResponse: null,
  otpLoading: false,
  verifyOTPErrorResponse: null,
  verifyOTPFinished: false,
};

export const lineProfile = createAsyncThunk<
  LineRegisterResponse,
  any,
  {
    rejectValue: ErrorResponse;
  }
>("request/createLineAccount", async (params: any, { rejectWithValue }) => {
  try {
    const response = await ApiClient.patch(LINE_REGISTER, params);
    return response.data;
  } catch (err) {
    if (err instanceof AxiosError) {
      return rejectWithValue(err.response?.data);
    }
  }
});

export const confirmOTP = createAsyncThunk<
  any,
  any,
  {
    rejectValue: ErrorResponse;
  }
>("request/confirmOTP", async (params: any, { rejectWithValue }) => {
  try {
    const response = await httpClientTD.post(LINE_VERIFY_OTP, params);
    return response.data;
  } catch (err) {
    if (err instanceof AxiosError) {
      return rejectWithValue(err.response?.data);
    }
  }
});

const lineRegisterSlice = createSlice({
  name: "lineRegister",
  initialState: initialValues,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(lineProfile.pending, (state) => {
      state.loading = true;
      state.registerResponse = null;
      state.registerErrorResponse = null;
    });
    builder.addCase(lineProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.registerResponse = action.payload;
    });
    builder.addCase(lineProfile.rejected, (state, action) => {
      state.loading = false;
      state.registerErrorResponse = action.payload ? action.payload : null;
    });

    builder.addCase(confirmOTP.pending, (state) => {
      state.otpLoading = true;
      state.verifyOTPErrorResponse = null;
      state.verifyOTPFinished = false;
    });
    builder.addCase(confirmOTP.fulfilled, (state) => {
      state.otpLoading = false;
      state.verifyOTPFinished = true;
    });
    builder.addCase(confirmOTP.rejected, (state, action) => {
      state.otpLoading = false;
      state.verifyOTPErrorResponse = action.payload ? action.payload : null;
    });
  },
});

export default lineRegisterSlice.reducer;
export const lineRegisterLoading = (store: RootState) => store.lineProfile.loading;
export const lineRegisterVerifyOTPFinished = (store: RootState) => store.lineProfile.verifyOTPFinished;
export const lineRegisterResponse = (store: RootState) => store.lineProfile.registerResponse;
export const lineRegisterErrorResponse = (store: RootState) => store.lineProfile.registerErrorResponse;
export const lineRegisterVerifyOTPErrorResponse = (store: RootState) =>
  store.lineProfile.verifyOTPErrorResponse;
export const lineRegisterOTPLoading = (store: RootState) => store.lineProfile.otpLoading;
