import "../src/style/global.scss";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import HeaderComponent from "./shared/component/HeaderComponent/HeaderComponent";
import { Layout } from "antd";
import { Outlet } from "react-router";
import Redirect from "./feature/Redirect/Redirect";
import StoreTracking from "./feature/StoreTracking/StoreTracking";
import LineRegister from "./feature/LineRegister/LineRegister";
import ErrorPage from "./feature/ErrorPage/ErrorPage";

const HeaderLayout = () => (
  <>
    <HeaderComponent />
    <Outlet />
  </>
);

function App() {
  return (
    <BrowserRouter>
      <Layout className="management-layout">
        <Layout>
          <Routes>
            <Route element={<HeaderLayout />}>
              <Route path="/t/:tracking_number" element={<StoreTracking />} />
            </Route>
            <Route path="/" element={<Redirect />} />
            <Route path="/line/register" element={<LineRegister />} />
            <Route path="error" element={<ErrorPage />} />
          </Routes>
        </Layout>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
