import { getAnalytics } from "firebase/analytics";
import { initializeApp as initializeFirebaseApp } from "firebase/app";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeFirebaseApp(firebaseConfig);

const analytics = getAnalytics(app);

export default analytics;
