import { RootState } from "../../../shared/store/store";
import { createSlice } from "@reduxjs/toolkit";

type ToggleState = {
  toggle: boolean;
};

const initialValues: ToggleState = {
  toggle: false,
};

const toggle = createSlice({
  name: "toggleSider",
  initialState: initialValues,
  reducers: {
    toggleSider: (state: ToggleState) => {
      if (state.toggle) {
        state.toggle = false;
      } else {
        state.toggle = true;
      }
    },
  },
});

export const { toggleSider } = toggle.actions;
export const isToggleSider = (store: RootState) => store.toggleSider.toggle;
export default toggle.reducer;
