export const phoneFormat = (phone: string) => {
  if (phone) {
    return phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  }
  return "";
};

export const phoneFormatMasked = (phoneNumber: string) => {
  const match = phoneNumber.match(/(\d{3})(\D{3})(\d{4})/);

  if (match) {
    return `${match[1]}-${match[2]}-${match[3]}`;
  }

  return phoneNumber;
};
