import buddhistEra from "dayjs/plugin/buddhistEra";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import localeTh from "dayjs/locale/th";
dayjs.extend(customParseFormat);
dayjs.extend(buddhistEra);
export const GetDateNow = () => {
  return dayjs().locale(localeTh).format("DD/MM/YYYY");
};

export const GetDate = (date: string) => {
  return dayjs(date).locale(localeTh).format("DD/MM/YYYY, HH:mm");
};

export const GetTime = (time: string) => {
  return dayjs(time).locale(localeTh).format("HH:mm");
};

export const GetNow = () => {
  return dayjs().locale(localeTh).format("DD/MM/YYYY, HH:mm");
};
